import { styled } from '@mui/material/styles'
import { Brightness4Outlined, Brightness7Rounded } from "@mui/icons-material"
import { IconButton } from "@mui/material"

const CustomIconButton = styled(props => <IconButton {...props}/>)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  '.MuiSvgIcon-root':{
    width: theme.spacing(3),
  },
}))

const ThemeButton = ({ mode }) => {
  return <CustomIconButton>
   { mode ? <Brightness7Rounded/> : <Brightness4Outlined/> }
  </CustomIconButton>
}

export default ThemeButton