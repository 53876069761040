export const pushToDataLayer = payload => {
  if(process.env.NEXT_PUBLIC_ENV === 'staging') return
  dataLayer.push(payload)
}

export const loginEvent = platform => {
  pushToDataLayer(function() {
    this.reset()
  })
  pushToDataLayer({
    event: 'login',
    method: platform,
  })
}

export const signUpEvent = platform => {
  pushToDataLayer(function() {
    this.reset()
  })
  pushToDataLayer({
    event: 'sign_up',
    method: platform,
  })
}

export const searchEvent = term => {
  pushToDataLayer(function() {
    this.reset()
  })
  pushToDataLayer({
    event: 'search',
    search_term: term,
  })
}

export const searchSelectionEvent = (term, selectedType, selectedName) => {
  pushToDataLayer(function() {
    this.reset()
  })
  pushToDataLayer({
    event: 'search_selection',
    search_term: term,
    selection_type: selectedType,
    selection_name: selectedName,
  })
}

export const exploreEventTicketsEvent = (eventName, eventId) => {
  pushToDataLayer(function() {
    this.reset()
  })
  pushToDataLayer({
    event: 'explore_event_tickets',
    event_name: eventName,
    event_id: eventId,
  })
}

export const boxCheckoutEvent = (type, forumName, forumId) => {
  pushToDataLayer(function() {
    this.reset()
  })
  pushToDataLayer({
    event: 'property_registration',
    property_type: type==1 ? 'box' : (type==2 ? 'stall' : 'unknown'),
    forum_name: forumName,
    forum_id: forumId,
  })
}
