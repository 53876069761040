import {
  logout as logoutQuery
} from '@graphql/auth.graphql'

export const logout = async apolloClient => {
  try {
    const resp = await apolloClient.mutate({
      mutation: logoutQuery,
    })
    return resp.data
  } catch (error) {
    throw new Error(error)
  }
}


