import { styled } from '@mui/material/styles'
import { Box, SwipeableDrawer } from '@mui/material'
import { useRouter } from 'next/navigation'

import baseConfig from '@baseConfig'
import BurgerMenuIcon from '@icons/BurgerMenuIcon'
import MessageIcon from '@icons/MessageIcon'
import StadiboxWhiteLogo from '@icons/logos/StadiboxWhiteLogo'
import SearchField from '@features/Search/SearchField'
import { useState } from 'react'
import MobileMenu from './MobileMenu'
import TawkToMessenger from '@features/App/Components/TawkToMessenger'
import Banner from './components/Banner'

// #region Styles

const Root = styled(Box, {
  shouldForwardProp: propName => propName !== 'hideSearch',
})(({ theme, hideSearch }) => ({
  padding: theme.spacing(2),
  paddingBottom: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  width: '100vw',
  background: theme.palette.gray[700],
  gap: theme.spacing(4),
  overflow: 'visible',
  boxShadow: `inset 0px ${theme.spacing(-2.5)} ${theme.palette.gray[200]}`,
  ...(hideSearch && {
    boxShadow: 'none',
    paddingBottom: theme.spacing(2),
  }),
}))

const TopContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}))

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: theme.spacing(4),
  aspectRatio: '1',
}))

const TalktoCircle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: theme.spacing(4),
  aspectRatio: '1',
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  '& svg path': {
    stroke: theme.palette.gray[700],
  },
}))

const StyledSwipeableDrawer = styled(props => <SwipeableDrawer {...props} />)(({ theme }) => ({
  '&, .MuiBackdrop-root, .MuiDrawer-paper': {
    width: '100vw',
    position: 'fixed',
  },
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.gray[100],
    overflow: 'scroll',
  },
}))

// #endregion

const NavMobile = ({ hideSearch = false, ...props }) => {
  const router = useRouter()
  const [open, setOpen] = useState(false)

  const handleHome = () => {
    router.push('/')
  }

  return (
    <>
      <Banner />
      <Root hideSearch={hideSearch}>
        <TopContainer>
          <IconContainer onClick={() => setOpen(true)}>
            <BurgerMenuIcon />
          </IconContainer>
          <StadiboxWhiteLogo onClick={handleHome} style={{ width: '143px', height: '22px' }} />
          <IconContainer>
            <TawkToMessenger />
          </IconContainer>
        </TopContainer>
        {!hideSearch && <SearchField />}
        <StyledSwipeableDrawer
          anchor={'left'}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          disableSwipeToOpen={true}
        >
          <MobileMenu onClose={() => setOpen(false)} />
        </StyledSwipeableDrawer>
      </Root>
    </>
  )
}

export default NavMobile
