import { useState, useEffect } from 'react'
import { MenuItem, Select } from '@mui/material'
import { styled } from '@mui/material/styles'

import useCurrency from '@hooks/useCurrency'

const CustomSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    color: theme.palette.gray[100],
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
  },
 
  '& svg': {
    fill: theme.palette.gray[100],
    zIndex: '1',
  },
  '& fieldset': {
    border: '2px solid',
    borderRadius: theme.spacing(2),
    borderColor: theme.palette.gray[100],
  },
  '& input': {
    color: theme.palette.gray[100],
  },
  '& .MuiInputLabel-formControl': {
    color: theme.palette.gray[100],
    '&.MuiInputLabel-shrink': {
      color: theme.palette.gray[100],
    },
  },
  '& .MuiChip-root': {
    color: theme.palette.gray[100],
    backgroundColor: theme.palette.components.white,
  },
  '& .MuiAutocomplete-tag': {
    color: theme.palette.gray[100],
  },
  '&:hover': {
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: theme.spacing(2),
  },
  [theme.breakpoints.down('md')]:{
    '& .MuiSelect-select': {
    color: theme.palette.gray[700],
  },
 
  '& svg': {
    fill: theme.palette.gray[700],
  },
  '& fieldset': {
    borderColor: theme.palette.gray[700],
  },
  '& input': {
    color: theme.palette.gray[700],
  },
  '& .MuiInputLabel-formControl': {
    color: theme.palette.gray[700],
    '&.MuiInputLabel-shrink': {
      color: theme.palette.gray[700],
    },
  },
  '& .MuiChip-root': {
    color: theme.palette.gray[700],
  },
  '& .MuiAutocomplete-tag': {
    color: theme.palette.gray[700],
  },
  },
}))

const CurrencySelect = ({ className, customStyles }) => {

  const { currency, setCurrency, getAvailableCurrencies } = useCurrency()

  const [currencies, setCurrencies] = useState([])

  const onSelectCurrency = selected => {
    const _selected = currencies.find(cur => cur.id === selected.id)
    setCurrency(_selected)
  }

  useEffect(() => {
    getAvailableCurrencies().then(currencies => {
      setCurrencies(currencies)
    }).catch(e => console.error(e))
  }, [])
  
  return currency && currencies.length>0 ?
    <CustomSelect
      customStyles={customStyles}
      value={currencies.find(c => c.id === currency.id)}
      onChange={e => onSelectCurrency(e.target.value)}
      size={'small'}
      renderValue={value => (value.id)}
    >
      {
        currencies.map(c => (
          <MenuItem value={c}>{c.id}</MenuItem>
        ))
      }
    </CustomSelect>
    : null
}

export default CurrencySelect
