import { Fragment } from 'react'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'

import NavDesktop from './NavDesktop'
import NavMobile from './NavMobile'

const Nav = ({ fromHome, changeRef, hideSearch = false, ...props }) => {

  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'))

  return <Fragment>
    {
      isMd ? <NavDesktop changeRef={changeRef} fromHome={fromHome}/> : <NavMobile hideSearch={hideSearch}/>
    }
  </Fragment>
}

export default Nav
