import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { useRouter } from 'next/router'

import useGlossary from '@hooks/useGlossary'
import PerformerIcon from '@icons/PerformerIcon'
import PropertyIcon from '@icons/PropertyIcon'
import ShoppingBagIcon from '@icons/ShoppingBagIcon'
import VenueIcon from '@icons/VenueIcon'

const Root = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
  width: '100vw',
  backgroundColor: theme.palette.gray[100],
  position: 'fixed',
  bottom: '0',
  boxShadow: theme.shadows.elevation.medium,
}))

const Option = styled(Box)(({ theme, selected = false }) => ({
  padding: `0 ${theme.spacing(1)}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: theme.palette.gray[500],
  flex: '1',
  'svg path': { 
    fill: theme.palette.gray[500],
  },
  ...(selected && {
    color: theme.palette.secondary.main,
    'svg path': {
      fill: theme.palette.secondary.main,
    },
  }),
}))

const BottomMenu = ({ hideSearch = false, ...props }) => {

  const { glossary } = useGlossary()
  const router = useRouter()

  const [selectedOp, setSelectedOp] = useState('home')

  const handleClick = option => {
    setSelectedOp(option)
    switch (option) {
    case 'home':
      router.push('/')
      break
    case 'purchases':
      router.push('/purchases')
      break
    case 'properties':
      router.push('/dashboard')
      break
    case 'profile':
      router.push('/profile')
      break
    default:
      break
    }
  }

  useEffect(() => {
    switch (router.pathname) {
    case '/':
      setSelectedOp('home')
      break
    case '/purchases':
      setSelectedOp('purchases')
      break
    case '/dashboard':
      setSelectedOp('properties')
      break
    case '/profile':
      setSelectedOp('profile')
      break
    default:
      setSelectedOp('home')
      break
    }
  }, [router.pathname])


  return (
    <Root>
      <Option selected={selectedOp === 'home'} onClick={() => handleClick('home')}>
        <VenueIcon/>
        <Box>
          { glossary('Home') }
        </Box>
      </Option>
      <Option selected={selectedOp === 'purchases'} onClick={() => handleClick('purchases')}>
        <ShoppingBagIcon/>
        <Box>
          { glossary('Purchases') }
        </Box>
      </Option>
      <Option selected={selectedOp === 'properties'} onClick={() => handleClick('properties')}>
        <PropertyIcon/>
        <Box>
          { glossary('Properties') }
        </Box>
      </Option>
      <Option selected={selectedOp === 'profile'} onClick={() => handleClick('profile')}>
        <PerformerIcon/>
        <Box>
          { glossary('Profile') }
        </Box>
      </Option>
    </Root>
  )
}

export default BottomMenu
