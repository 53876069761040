import { Menu, MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles'
import PersonIcon from '@mui/icons-material/Person'
import LogoutIcon from '@mui/icons-material/Logout'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import FeedIcon from '@mui/icons-material/Feed'
import { useRouter } from 'next/router'
import SettingsIcon from '@mui/icons-material/Settings'

import baseConfig from '@baseConfig'
import useGlossary from '@hooks/useGlossary'
import CustomDivider from '@components/CustomDivider'
import PropertyIcon from '@icons/PropertyIcon'
import ShoppingBagIcon from '@icons/ShoppingBagIcon'
import { useLogout } from './hooks'
import { useSession } from '@features/App/hooks'

const CustomMenuItem = styled(props => <MenuItem {...props}/>)(({ theme }) => ({
  justifyContent: 'flex-start',
  color: theme.palette.gray[700],
  alignItems: 'center',
  font: theme.font.roboto.paragraph.medium,
  gap: theme.spacing(2),
}))

const CustomMenu = styled(props => <Menu {...props}/>)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.gray[100],
  },
  '& .MuiList-root': {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))

const ProfileSettingsNavButton = ({ href, external, onClick, ...props }) => {

  const router = useRouter()
  const selected = !external && router.pathname === href

  const handleClick = (e) => {
    e.preventDefault()
    if (external) {
      window.open(href, '_blank')
    } else {
      router.push(href)
    }
  }

  return <div  onClick={handleClick}>
    <CustomMenuItem selected={selected} onClick={onClick} {...props}>{props.children}</CustomMenuItem>
  </div>
}


const ProfileMenu = ({ anchorEl, setAnchorEl }) => {

  const { glossary } = useGlossary()
  const { userLogout } = useLogout()
  const router = useRouter()
  const { session } = useSession()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const LogoutHandler = () => {
    if(!confirm(glossary('SureLogout'))) return
    userLogout()
      .then(resp => {
        router.push('/')
      })
      .catch(err => console.error(err))
  }

  return (
    <div>
      <CustomMenu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        elevation={2}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
       {(session && session.user.type.admin) && <>
       <ProfileSettingsNavButton href={baseConfig.dashboardUrl} external onClick={handleClose}>
          <SettingsIcon/> {glossary('Console')}
        </ProfileSettingsNavButton>
        <CustomDivider/>
        </>
        }
        <ProfileSettingsNavButton href={'/publications'} onClick={handleClose}>
          <FeedIcon color='#212121' /> {glossary('Publications')}
        </ProfileSettingsNavButton>
        <CustomDivider/>
        <ProfileSettingsNavButton href={'/dashboard/userProperties'} onClick={handleClose} >
          <PropertyIcon color='#212121' /> {glossary('MyProperties')}
        </ProfileSettingsNavButton>
        <CustomDivider/>
        <ProfileSettingsNavButton href={'/purchases'} onClick={handleClose} >
          <ShoppingBagIcon color='#212121' /> {glossary('MyPurchases')}
        </ProfileSettingsNavButton>
        <CustomDivider/>
        <ProfileSettingsNavButton href={'/sales'} onClick={handleClose} >
          <AttachMoneyIcon color= '#212121'  />{glossary('Sales')}
        </ProfileSettingsNavButton>
        <CustomDivider/>
        <ProfileSettingsNavButton href={'/profile'} onClick={handleClose} >
          <PersonIcon /> {glossary('MyProfile')}
        </ProfileSettingsNavButton>
        <CustomDivider/>
        <CustomMenuItem sx={{ color: '#FF5C47' }} onClick={() => {
          LogoutHandler()
          setTimeout(handleClose, 0)
        }}>
          <LogoutIcon color ='#FF5C47' /> {glossary('Logout')}  
        </CustomMenuItem>
      </CustomMenu>
    </div>
  )
}

export default ProfileMenu
