import { useContext } from 'react'
import { MenuItem, Select } from '@mui/material'
import { styled } from '@mui/material/styles'

import Context from '@context/Locale'

const CustomSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    color: theme.palette.gray[700],
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    //height: 'px',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
  },
 
  '& svg': {
    fill: theme.palette.gray[700],
    zIndex: '1',
  },
  '& fieldset': {
    border: '2px solid',
    borderRadius: theme.spacing(2),
    borderColor: theme.palette.gray[700],
  },
  '& input': {
    color: theme.palette.gray[700],
  },
  '& .MuiInputLabel-formControl': {
    color: theme.palette.gray[100],
    '&.MuiInputLabel-shrink': {
      color: theme.palette.gray[100],
    },
  },
  '& .MuiChip-root': {
    color: theme.palette.gray[100],
    backgroundColor: theme.palette.components.white,
  },
  '& .MuiAutocomplete-tag': {
    color: theme.palette.gray[100],
  },
  '&:hover': {
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: theme.spacing(2),
  },
  [theme.breakpoints.up('md')]:{
    '& .MuiSelect-select': {
    color: theme.palette.gray[100],
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
  },
 
  '& svg': {
    fill: theme.palette.gray[100],
    zIndex: '1',
  },
  '& fieldset': {
    border: '2px solid',
    borderRadius: theme.spacing(1),
    borderColor: theme.palette.gray[100],
  },
  '& input': {
    color: theme.palette.gray[700],
  },
  '& .MuiInputLabel-formControl': {
    color: theme.palette.gray[100],
    '&.MuiInputLabel-shrink': {
      color: theme.palette.gray[100],
    },
  },
  '& .MuiChip-root': {
    color: theme.palette.gray[100],
    backgroundColor: theme.palette.components.white,
  },
  '& .MuiAutocomplete-tag': {
    color: theme.palette.gray[100],
  },
  '&:hover': {
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
  },
  },
}))

const LanguageSelect = ({  }) => {

  const { currentLocale, changeLocale } = useContext(Context)

  const localeChange = event => {
    const newLocale = event.target.value
    changeLocale(newLocale)
  }

  return <CustomSelect
    value={currentLocale}
    onChange={localeChange}
    size={'small'}
  >
    <MenuItem value='es'>{'ES'}</MenuItem>
    <MenuItem value='en'>{'EN'}</MenuItem>
    <MenuItem value='pt'>{'PT'}</MenuItem>
 </CustomSelect>
}

export default LanguageSelect
