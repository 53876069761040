/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'

const TransparentLogo = props => {

  return <SvgIcon width='92' height='58' viewBox='0 0 92 58' fill='none' {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      d='M53.8396 45.2896C9.88474 52.4111 -4.951 21.1734 34.8709 1.80687C34.8925 1.79918 34.91 1.78331 34.9198 1.76277C34.9295 1.74222 34.9306 1.71867 34.9229 1.69731C34.9151 1.67594 34.8991 1.65851 34.8784 1.64884C34.8577 1.63917 34.834 1.63806 34.8124 1.64575C-20.8785 19.3817 -5.63334 65.9129 51.3767 56.8387L53.8396 45.2896Z'
      fill='white'
      fillOpacity='0.2'
    />
    <path
      xmlns='http://www.w3.org/2000/svg'
      d='M40.7194 1.14952C97.6645 -7.87315 112.806 38.6322 57.2577 56.4198C57.2346 56.4246 57.2106 56.421 57.1899 56.4096C57.1693 56.3982 57.1536 56.3798 57.1457 56.3577C57.1377 56.3357 57.1381 56.3115 57.1468 56.2898C57.1554 56.268 57.1718 56.2501 57.1927 56.2393C96.9042 36.8599 82.1009 5.57706 38.2435 12.6792L40.7194 1.14952Z'
      fill='white'
      fillOpacity='0.2'
    />
  </SvgIcon>
}

export default TransparentLogo
