import { useCallback, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { logout } from '../services/logout'
import useSession from '@hooks/useSession'

export default function useLogout() {

  const apolloClient = useApolloClient()
  const { clearSession } = useSession()

  const [loading, setLoading] = useState(false)

  const userLogout = useCallback ( variables => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await logout(apolloClient, variables)
        clearSession()
        resolve(response)
        setLoading(false)
      } catch (error){
        reject(error)
      }
    })
  }, [apolloClient])

  return {
    loading,
    userLogout,
  }
}
