import { Fragment, useEffect, useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { Avatar, Box, Button, useMediaQuery } from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'
import { useRouter } from 'next/navigation'

import baseConfig from '@baseConfig'
import useSession from '@hooks/useSession'
import useGlossary from '@hooks/useGlossary'
import StadiboxWhiteLogo from '@icons/logos/StadiboxWhiteLogo'
import BlueButton from '@components/BlueButton'
import WhiteOutlinedButton from '@components/WhiteOutlinedButton'
import SearchField from '@features/Search/SearchField'
import CurrencySelect from '@features/Currency/CurrencySelect'
import LocationPicker from '@features/Filters/Location/LocationPicker'
import ProfileMenu from './ProfileMenu'
import Banner from './components/Banner'

const Root = styled(Box)(({ theme }) => ({
  top: '12px',
  zIndex: '99',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  background: theme.palette.semantic.default,
  transition: 'all 0.3s ease-out',
  height: theme.spacing(11),
  '& .Search-Container': {
    width: '35%',
    opacity: '1',
  },
  '& .hideOptions': {
    width: '0',
    opacity: '0',
    overflow: 'hidden',
    transition: 'all 0.3s ease-out',
  },
  '&.transparent': {
    background: theme.palette.gradient.linear.down,
    '& .Search-Container': {
      width: '0%',
      opacity: '0',
      paddingRight: '0',
    },
    '& .hideOptions': {
      width: '20%',
      opacity: '1',
    },
  },
}))

const NavContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: theme.spacing(5),
  width: 'max(100%, 1600px)',
  maxWidth: '1600px',
  justifyContent: 'space-around',
  gap: theme.spacing(3),
}))

const SearchContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  overflow: 'hidden',
  transition: 'all 0.7s ease-out',
}))

const MenuContainer = styled(Box)(({ theme }) => ({
  font: theme.font.rubik.paragraph.medium,
  color: theme.palette.primary.contrastText,
}))

const Container = styled(props => <Box {...props}/>)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
}))

const ProfileButton = styled(props => <Button {...props}/>)(({ theme }) => ({
  padding: `${theme.spacing(0)} ${theme.spacing(1)} ${theme.spacing(0)} ${theme.spacing(0)}`,
  backgroundColor:'#212121',
  [theme.breakpoints.up('md')]: {
    width: 'auto',
    height: '42px',
    marginLeft: '20px',
  },
  borderRadius: '24px',
  textTransform: 'capitalize',
  '&:hover': {
    opacity: '25%',
    background: 'inherit',
  },
}))

const CustomAvatar = styled(props => <Avatar {...props}/>)(({ theme }) => ({
  width: theme.spacing(3.5),
  height: theme.spacing(3.5),
  marginRight: '10px',
  marginLeft: '10px',
  backgroundColor: 'transparent',
  [theme.breakpoints.up('md')]: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    left: '-4px',
  },
}))

const NavDesktop = ({ fromHome, changeRef, ...props }) => {

  const { glossary } = useGlossary()
  const theme = useTheme()
  //const showOptions = useMediaQuery(theme.breakpoints.up(1500))
  const [scrolled, setScrolled] = useState(false)
  const { session } = useSession()
  const [profileAnchorEl, setProfileAnchorEl] = useState()

  useEffect(() => {
    if (!fromHome) return
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        setScrolled(!entry.isIntersecting)
      })
    }, {
      rootMargin: '-200px 0px 0px 0px',
    })

    if (changeRef.current)
      observer.observe(changeRef.current)

    return () => {
      if (changeRef.current)
        observer.unobserve(changeRef.current)

    }
  }, [])

  const router = useRouter()

  const handleHome = () => {
    // ** uncomment this when the release is ready 
    // router.push({
    //   pathname: `https://${baseConfig.rootDomain}`,
    // }).then(() => {})
    // This is just a temporary fix for testing
    router.push('/')
  }

  const handleProfileOpen = event => {
    setProfileAnchorEl(event.currentTarget)
  }


  const profileButton = <ProfileButton
    variant='contained'
    disableElevation
    endIcon = {<ArrowDropDown/>}
    onClick={handleProfileOpen}
  >
    <CustomAvatar src={session
      ? session?.user?.profileImage ? 
        `${baseConfig.previewRoot}/${session?.user?.profileImage?.id}` :
        ''
      : ''}
    alt={session ? session.user.fullName : ''}/>
    {session ? session.user.firstName : ''}
  </ProfileButton>

  return <>
  <Banner/>
  <Root className={fromHome && !scrolled ? 'transparent' : ''} sx={{ position: fromHome ? 'fixed' : 'sticky', width: fromHome ? '100vw': '100%'}}>
    <NavContainer>
      <StadiboxWhiteLogo onClick={handleHome} style={{ width: '160px', height: '24px' }}/>
      <SearchContainer className={'Search-Container'}>
        <SearchField/>
      </SearchContainer>
      <Container sx={{ alignItems: 'center' }}>
        <LocationPicker useLocationContext={true} variant={'outline'}/>
        <CurrencySelect/>
        { !scrolled &&
          <WhiteOutlinedButton variant='container' href={'/box-checkout'}>
            { glossary('RegisterMyProperty') }
          </WhiteOutlinedButton>
        }
        { !session && 
          <BlueButton variant='container' color='secondary' href={'/login'}>
            { glossary('Login') }
          </BlueButton>
        }
        {
          session && <Fragment>
            {profileButton}
          </Fragment>
        }
      </Container>
    </NavContainer>
    <ProfileMenu anchorEl={profileAnchorEl} setAnchorEl={setProfileAnchorEl}/>
  </Root>
  </>
}

export default NavDesktop
