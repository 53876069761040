import { SvgIcon } from '@mui/material'
import { useContext } from 'react'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'

const XLightIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='17' height='15' viewBox='0 0 17 15' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M13.3882 0H15.9949L10.2999 6.35458L17 15H11.7541L7.64575 9.75591L2.94383 15H0.33575L6.42742 8.20288L0 0.0006915H5.37908L9.09287 4.79394L13.3882 0ZM12.4738 13.4774H13.918L4.59425 1.44309H3.04442L12.4738 13.4774Z'
      fill={color}
    />
  </SvgIcon>
)

const XDarkIcon = ({ color = '#8FA8BD' }) => (
  <SvgIcon width='17' height='15' viewBox='0 0 17 15' fill='none'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      // eslint-disable-next-line max-len
      d='M13.3882 0H15.9949L10.2999 6.35458L17 15H11.7541L7.64575 9.75591L2.94383 15H0.33575L6.42742 8.20288L0 0.0006915H5.37908L9.09287 4.79394L13.3882 0ZM12.4738 13.4774H13.918L4.59425 1.44309H3.04442L12.4738 13.4774Z'

      fill={color}
    />
  </SvgIcon>
)

const XIcon = props => {
  const { darkMode } = useContext(Context)
  return darkMode ? <XDarkIcon {...props} /> : <XLightIcon {...props} />
}

export default XIcon
