/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'

const StadiboxLogoMark = props => {

  return <SvgIcon viewBox='0 0 98 59' fill='none' {...props}>
    <path d='M56.3857 46.0705C10.3522 53.3147 -5.18513 21.5384 36.52 1.83802C36.5425 1.8302 36.5609 1.81406 36.5711 1.79316C36.5813 1.77226 36.5825 1.74831 36.5744 1.72657C36.5663 1.70484 36.5495 1.6871 36.5278 1.67727C36.5061 1.66743 36.4813 1.6663 36.4587 1.67413C-21.8658 19.7159 -5.89974 67.0493 53.8063 57.8186L56.3857 46.0705Z' fill='#82CD1C'/>
    <path d='M42.645 1.16934C102.283 -8.00889 118.14 39.2983 59.9654 57.3925C59.9413 57.3975 59.916 57.3938 59.8945 57.3822C59.8729 57.3706 59.8564 57.3518 59.8481 57.3294C59.8398 57.307 59.8402 57.2824 59.8493 57.2603C59.8583 57.2381 59.8754 57.2199 59.8974 57.209C101.487 37.4954 85.9835 5.67322 40.0521 12.8978L42.645 1.16934Z' fill='#82CD1C'/>
  </SvgIcon>
}

export default StadiboxLogoMark
