import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

import { useGlossary } from '@features/App/hooks'

const Root = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  zIndex: '100',
  width: '100vw',
  padding: theme.spacing(0.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  width: '100%',
  background: theme.palette.gray[100],
  gap: theme.spacing(0.5),
  [theme.breakpoints.down('md')]:{
    position: 'sticky',
    flexDirection: 'column',
    gap: theme.spacing(0.12),
  }
}))

const Text = styled('p')(({ theme }) => ({
    font: theme.font.roboto.paragraph.small,
    fontSize: '10px',
    margin: '0',
    '& span': {
      fontWeight: '700',
    }
}))


const Banner = ({  ...props }) => {
  
  const { glossary } = useGlossary()

  return (
    <Root>
      <Text>
        {glossary('YourOrder')} <span>{glossary('IsSecured')}</span>
      </Text>
      <Text>
        { glossary('TicketPriceCanChange')}
      </Text>
    </Root>
  )
}

export default Banner
