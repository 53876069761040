import { useContext, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, IconButton } from '@mui/material'
import TranslateRoundedIcon from '@mui/icons-material/TranslateRounded'
import SupportAgentOutlined from '@mui/icons-material/SupportAgentOutlined'
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded'
import SportsSoccerRoundedIcon from '@mui/icons-material/SportsSoccerRounded'
import SettingsIcon from '@mui/icons-material/Settings'
import LogoutIcon from '@mui/icons-material/Logout'
import ReviewsRoundedIcon from '@mui/icons-material/ReviewsRounded'
import { useRouter } from 'next/router'

import baseConfig from '@baseConfig'
import useGlossary from '@hooks/useGlossary'
import PerformerIcon from '@icons/PerformerIcon'
import PropertyIcon from '@icons/PropertyIcon'
import ShoppingBagIcon from '@icons/ShoppingBagIcon'
import VenueIcon from '@icons/VenueIcon'
import { StadiboxLogoMark, StadiboxWhiteLogo } from '@features/App/Components/Icons/logos'
import {
  ArrowGraphIcon,
  CalendarWithLinesIcon,
  CloseXIcon,
  DocLinesIcon,
  EnterpriseIcon,
  FAQIcon,
  MoneyBagIcon,
  OwnersIcon,
  PrivacyIcon,
  WeIcon,
} from '@features/App/Components/Icons'
import { useSession, useTheme } from '@features/App/hooks'
import { CustomDivider } from '@features/App/Components'
import GreenButton from '@features/App/Components/GreenButton'
import { useLogout } from './hooks'
import LanguageSelect from './components/LanguageSelect'
import ThemeButton from './components/ThemeButton'
import { ThemeCustomContext as Context } from '@context/ThemeContextProvider'
import CurrencySelect from '@features/Currency/CurrencySelect'

const Root = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(0)} ${theme.spacing(1)}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
  width: '100vw',
  zIndex: 1000,
}))

const Option = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(1)}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  color: theme.palette.gray[500],
  flex: '1',
  'svg path': {
    fill: theme.palette.gray[700],
  },
}))

const TopBox = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.gray[700],
  width: '100vw',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1000,
}))

const CustomIconButton = styled(props => <IconButton {...props} />)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  '.MuiSvgIcon-root': {
    width: theme.spacing(3),
  },
}))

const BlockBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

const Label = styled(Box)(({ theme }) => ({
  font: theme.font.roboto.paragraph.large,
  fontWeight: '400',
  color: theme.palette.gray[700],
}))

const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  overflow: 'auto',
  width: '100%',
  height: '100vh',
  paddingTop: `calc(${theme.spacing(3)} + 64px)`,
  paddingBottom: `calc(${theme.spacing(6)} + 80px)`,
  marginBottom: `calc(${theme.spacing(3)} + 64px)`,
  '&::-webkit-scrollbar': {
    width: '0px',
    backgroundColor: 'transparent',
  },
}))

const BoxLogin = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100vw',
  position: 'fixed',
  bottom: 0,
  left: 0,
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
  backgroundColor: theme.palette.gray[100],
  zIndex: 1000,
}))

const MobileMenu = ({ onClose }) => {
  const { darkMode, toggleTheme } = useContext(Context)
  const { glossary } = useGlossary()
  const { theme } = useTheme()
  const router = useRouter()
  const { session } = useSession()
  const { userLogout } = useLogout()

  const LogoutHandler = () => {
    userLogout()
      .then(resp => {
        onClose()
        router.push('/')
      })
      .catch(err => console.error(err))
  }

  const handleClick = option => {
    onClose()
    switch (option) {
      case 'home':
        router.push('/')
        break
      case 'purchases':
        router.push('/purchases')
        break
      case 'properties':
        router.push('/dashboard')
        break
      case 'profile':
        router.push('/profile')
        break
      case 'about':
        router.push('/about')
        break
      case 'company':
        router.push('/company-services')
        break
      case 'faq':
        router.push('/faq')
        break
      case 'rent':
        router.push('/rent')
        break
      case 'owners':
        router.push('/owners')
        break
      case 'sale':
        router.push('/box-sale')
        break
      case 'contact':
        router.push('/contact')
        break
      case 'blog':
        router.push('/blog')
        break
      case 'experience':
        router.push('/stadibox-experience')
        break
      case 'privacy':
        router.push('/privacy')
        break
      case 'mexico':
        router.push('/mexico2026')
        break
      default:
        break
    }
  }

  return (
    <Root>
      <TopBox>
        <StadiboxWhiteLogo style={{ width: '143px', height: '22px' }} />
        <CustomIconButton onClick={onClose}>
          <CloseXIcon color={theme.palette.gray[100]} />
        </CustomIconButton>
      </TopBox>
      <Content>
        <BlockBox>
        <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingRight: '16px',
              alignItems: 'center',
            }}
          >
            <Option>
              <TranslateRoundedIcon />
              <Label>{glossary('ChangeLanguage')}</Label>
            </Option>
            <LanguageSelect />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingRight: '16px',
              alignItems: 'center',
            }}
          >
            <Option>
              <CurrencyExchangeRoundedIcon />
              <Label>{glossary('ChangeExchange')}</Label>
            </Option>
            <CurrencySelect />
          </Box>
          <Option onClick={() => handleClick('home')}>
            <VenueIcon />
            <Label>{glossary('Home')}</Label>
          </Option>
          <Option onClick={() => handleClick('purchases')}>
            <ShoppingBagIcon />
            <Label>{glossary('Purchases')}</Label>
          </Option>
          <Option onClick={() => handleClick('properties')}>
            <PropertyIcon />
            <Label>{glossary('Properties')}</Label>
          </Option>
          <Option onClick={() => handleClick('profile')}>
            <PerformerIcon />
            <Label>{glossary('Profile')}</Label>
          </Option>
          {session && session.user.type.admin && (
            <Option onClick={() => window.open(baseConfig.dashboardUrl, '_blank')}>
              <SettingsIcon />
              <Label>{glossary('Console')}</Label>
            </Option>
          )}
        </BlockBox>
        <CustomDivider sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }} />
        <BlockBox>
          <Option onClick={() => handleClick('about')}>
            <WeIcon />
            <Label>{glossary('AboutUs')}</Label>
          </Option>
          <Option onClick={() => handleClick('company')}>
            <EnterpriseIcon />
            <Label>{glossary('Companies')}</Label>
          </Option>
          <Option onClick={() => handleClick('owners')}>
            <OwnersIcon />
            <Label>{glossary('Owners')}</Label>
          </Option>
          <Option onClick={() => handleClick('sale')}>
            <MoneyBagIcon />
            <Label>{glossary('SaleBoxesAndStalls')}</Label>
          </Option>
          <Option onClick={() => handleClick('experience')}>
            <StadiboxLogoMark />
            <Label>{glossary('StadiboxExperiences')}</Label>
          </Option>
          <Option onClick={() => handleClick('mexico')}>
            <SportsSoccerRoundedIcon />
            <Label>{glossary('mexico2026')}</Label>
          </Option>
          <Option onClick={() => handleClick('rent')}>
            <CalendarWithLinesIcon />
            <Label>{glossary('AnualRent')}</Label>
          </Option>
          <Option onClick={() => handleClick('contact')}>
            <SupportAgentOutlined />
            <Label>{glossary('ContactUs')}</Label>
          </Option>
        </BlockBox>
        <CustomDivider sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }} />
        <BlockBox>
          <Option onClick={() => handleClick('blog')}>
            <ReviewsRoundedIcon />
            <Label>{'Blog'}</Label>
          </Option>
          <Option
            onClick={() =>
              window.open(
                'https://cdn.stadibox.com/m4zVSTyWZZ/TERMINOS%20Y%20CONDICIONES%20STADIUM%20SUITES%2019.06.23.pdf',
                '_blank'
              )
            }
          >
            <DocLinesIcon />
            <Label>{glossary('TermsAndCo')}</Label>
          </Option>
          <Option onClick={() => handleClick('faq')}>
            <FAQIcon />
            <Label>{glossary('FAQ')}</Label>
          </Option>
          <Option onClick={() => handleClick('privacy')}>
            <PrivacyIcon />
            <Label>{glossary('Privacy')}</Label>
          </Option>
          {/* <Option onClick={toggleTheme}>
            <ThemeButton mode={darkMode} />
            <Label>{glossary('ChangeTheme')}</Label>
          </Option> */}
        </BlockBox>
      </Content>
      <BoxLogin>
        {session ? (
          <Option onClick={LogoutHandler}>
            <LogoutIcon />
            <Label>{glossary('Logout')}</Label>
          </Option>
        ) : (
          <GreenButton href={'/login'} sx={{ width: '100%', height: '32px' }}>
            {glossary('Login')}
          </GreenButton>
        )}
      </BoxLogin>
    </Root>
  )
}

export default MobileMenu
