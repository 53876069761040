import { styled, useTheme } from '@mui/material/styles'
import { Box, useMediaQuery } from '@mui/material'
import { useRouter } from 'next/router'

import useGlossary from '@hooks/useGlossary'
import StadiboxWhiteLogo from '@icons/logos/StadiboxWhiteLogo'
import { STADIBOX_INFO } from '@constants/CompanyData'

import CashIcon from '@icons/CashIcon'
import FacebookIcon from '@icons/FacebookIcon'
import InstagramIcon from '@icons/InstagramIcon'
import MailIcon from '@icons/MailIcon'
import PhoneIcon from '@icons/PhoneIcon'
import ShieldLockIcon from '@icons/ShieldLockIcon'
import StadiboxProtectionIcon from '@icons/StadiboxProtectionIcon'
import SupportIcon from '@icons/SupportIcon'
import TicketStarIcon from '@icons/TicketStarIcon'
import TiktokIcon from '@icons/TiktokIcon'
import XIcon from '@icons/XIcon'
import Link from 'next/link'
import LanguageSelect from './components/LanguageSelect'

const Root = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(6)} ${theme.spacing(8)} ${theme.spacing(4)} ${theme.spacing(8)}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: theme.spacing(6),
  width: '100vw',
  background: theme.palette.tertiary.main,
  color: theme.palette.gray[200],
}))

const MainGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, auto) 1fr',
  gridTemplateRows: 'repeat(2, auto)',
  gap: `${theme.spacing(6)} ${theme.spacing(10)}`,
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: 'repeat(3, auto) 1fr',
  },
}))

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
}))

const SocialContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
}))

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  width: theme.spacing(4),
  height: theme.spacing(4),
  backgroundColor: theme.palette.gray[200],
  '& svg path': {
    fill: theme.palette.tertiary.main,
  },
}))

const Label = styled(Box)(({ theme }) => ({
  font: theme.font.roboto.paragraph.medium,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  cursor: 'pointer',
  '& a': {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  '& svg path': {
    fill: theme.palette.gray[200],
  },
}))

const LabelLarge = styled(Box)(({ theme }) => ({
  font: theme.font.roboto.paragraph.large,
}))

const LabelMain = styled(Box)(({ theme }) => ({
  font: theme.font.rubik.paragraph.large,
  textDecoration: 'underline',
  fontWeight: '600',
  marginBottom: theme.spacing(3),
}))

const LabelMini = styled(Box)(({ theme }) => ({
  font: theme.font.rubik.paragraph.minimum,
}))

const BottomData = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  width: '100%',
}))

const Footer = props => {
  const { glossary } = useGlossary()
  const theme = useTheme()
  const isLg = useMediaQuery(theme.breakpoints.up('lg'))
  const router = useRouter()

  return (
    <Root>
      <MainGrid>
        <Container sx={{ gridArea: '1 / 1 / span 1 / span 1' }}>
          <StadiboxWhiteLogo style={{ width: '288px', height: 'calc( ( 288px * 59 ) / 392 )' }} />
          <LabelLarge> {glossary('NobodyWins')} </LabelLarge>
          <SocialContainer>
            <Link href={'https://x.com/stadibox'}>
            <IconContainer sx={{'& svg': {
                width: theme.spacing(2.5),
                height: theme.spacing(2.5),
            },}}>
              <XIcon />
            </IconContainer>
            </Link>
            <Link href={'https://www.facebook.com/Stadibox/'}>
            <IconContainer >
              <FacebookIcon />
            </IconContainer>
            </Link>
            <Link href={'https://www.instagram.com/stadibox'}>
            <IconContainer >
              <InstagramIcon />
            </IconContainer>
            </Link>
            <Link href={'https://www.tiktok.com/@stadibox?_t=8sTNvv0cOPd&_r=1'}>
            <IconContainer>
              <TiktokIcon />
            </IconContainer>
            </Link>
          </SocialContainer>
        </Container>
        <Container sx={{ gridArea: '2 / 1 / span 1 / span 1' }}>
          <Label>
            {' '}
            <StadiboxProtectionIcon /> {glossary('StadiboxProtection')}{' '}
          </Label>
          <Label>
            {' '}
            <TicketStarIcon /> {glossary('GuaranteedOriginalTickets')}{' '}
          </Label>
          <Label>
            {' '}
            <SupportIcon /> {glossary('PersonalizedAttention')}{' '}
          </Label>
          <Label>
            {' '}
            <CashIcon /> {glossary('RefundOfYourPurchase')}{' '}
          </Label>
          <Label>
            {' '}
            <ShieldLockIcon /> {glossary('SecurePayments')}{' '}
          </Label>
        </Container>
        <Container sx={{ gridArea: '1 / 2 / span 2 / span 1' }}>
          <LabelMain> {glossary('Services')} </LabelMain>
          <Label onClick={() => router.push('/blog')}>{'Blog'}</Label>
          <Label onClick={() => router.push('/owners')}> {glossary('Owners')} </Label>
          <Label onClick={() => router.push('/stadibox-experience')}> {glossary('StadiboxExperiences')} </Label>
          <Label onClick={() => router.push('/company-services')}> {glossary('Companies')} </Label>
          <Label onClick={() => router.push('/rent')}> {glossary('SeasonalRent')} </Label>
          <Label onClick={() => router.push('/box-sale')}> {glossary('BuyAndSell')} </Label>
          <Label onClick={() => router.push('/mexico2026')}> {glossary('mexico2026')} </Label>
        </Container>
        <Container
          sx={{
            gridArea: isLg ? '1 / 3 / span 2 / span 1' : '1 / 3 / span 1 / span 1',
          }}
        >
          <LabelMain> {glossary('Help')} </LabelMain>
          <Label onClick={() => router.push('/contact')}> {glossary('ContactUs')}</Label>
          <Label onClick={() => router.push('/about')}> {glossary('AboutUs')} </Label>
          <Label onClick={() => router.push('/faq')}> {glossary('FAQ')} </Label>
          <Label onClick={() => router.push('/privacy')}> {glossary('Privacy')} </Label>
          <Label
            onClick={() =>
              window.open(
                'https://cdn.stadibox.com/m4zVSTyWZZ/TERMINOS%20Y%20CONDICIONES%20STADIUM%20SUITES%2019.06.23.pdf',
                '_blank'
              )
            }
          >
            {glossary('TAC')}
          </Label>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Label>{glossary('ChangeLanguage')}</Label>
            <LanguageSelect />
          </Box>
        </Container>
        <Container
          sx={{
            gridArea: isLg ? '1 / 4 / span 2 / span 1' : '2 / 3 / span 1 / span 1',
          }}
        >
          <LabelMain> {glossary('Contact')} </LabelMain>
          <Label>
            {' '}
            <PhoneIcon /> {STADIBOX_INFO.contact.phone}{' '}
          </Label>
          <Label>
            {' '}
            <a href={`mailto:${STADIBOX_INFO.contact.email}`}>
              <MailIcon />
              {STADIBOX_INFO.contact.email}
            </a>
          </Label>
        </Container>
        <Container
          sx={{
            gridArea: isLg ? '1 / 5 / span 2 / span 1' : '1 / 4 / span 2 / span 1',
            alignItems: 'end',
          }}
        >
          <Link  href={'https://apps.apple.com/us/app/stadibox/id1627159228'} > 
            <img src={'/svg/appstore.svg'} alt='appstore' />
          </Link>
          <Link href={'https://play.google.com/store/apps/details?id=com.stadibox.app'}>
            <img src={'/svg/googleplay.svg'} alt='googleplay' />
          </Link>
        </Container>
      </MainGrid>
      <BottomData>
        <Container>
          <Label> {glossary('Office')} </Label>
          <LabelMini>
            {STADIBOX_INFO.address.street}&nbsp;
            {glossary('Number')}&nbsp;
            {STADIBOX_INFO.address.number}&nbsp;
            {glossary('Neighborhood')}&nbsp;
            {STADIBOX_INFO.address.neighborhood}&nbsp;
            {glossary('District')}&nbsp;
            {STADIBOX_INFO.address.cityBorough},&nbsp;
            {glossary('PC')}&nbsp;
            {STADIBOX_INFO.address.cp},&nbsp;
            {STADIBOX_INFO.address.city.short}
          </LabelMini>
        </Container>
        <Container>
          <LabelMini>
            ©{STADIBOX_INFO.year}.&nbsp;
            {glossary('AllRightsReserved')}.&nbsp;|&nbsp;
            {glossary('MexicoCity')}
          </LabelMini>
        </Container>
      </BottomData>
    </Root>
  )
}

export default Footer
