export const STADIBOX_INFO = {
  address: {
    street: 'Av. Miguel A. Quevedo',
    number: '621',
    neighborhood: 'Romero de Terreros',
    cityBorough: 'Coyoacán',
    cp: '04310',
    city: {
      short: 'CDMX',
      full: 'Ciudad de México',
    },
  },
  year: '2023',
  name: 'Stadibox',
  fiscalName: 'Stadium Suites',
  contact: {
    phone: '+52 1 55 6709 5004',
    email: 'hola@stadibox.com',
  },
}
